import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Colors from "../../../assets/styles/Colors";
import { EnabledLanguage, supportedLocales } from "../../../domain/types";
import { RootState, useAppSelector } from "../../../store";
import { AuthState } from "../../../store/authSlice";
import { MenuList } from "./AdminMenu";

import {
  AppstoreOutlined,
  FolderOpenOutlined,
  FolderOutlined,
  GlobalOutlined,
  LoginOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  MoonOutlined,
  SunOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Drawer,
  Flex,
  Input,
  Layout,
  Menu,
  MenuProps,
  Modal,
  Popover,
  QRCode,
  Row,
  theme,
  Tooltip,
  Typography,
} from "antd";
import { useTranslation } from "react-i18next";
import { RiMenuSearchLine } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useDebounce } from "use-debounce";
import api from "../../../api";
import Responsive from "../../../assets/config/Responsive";
import {
  AppState,
  setAppTheme,
  setEditedlanguage,
  setLanguage,
  setLoading,
  setSearchKeyword,
} from "../../../store/appSlice";

const { Text } = Typography;

const { Header, Sider, Content } = Layout;

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}
//
const Admin_layout: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  //
  const navigate = useNavigate();
  //
  const { t } = useTranslation();
  //
  const { i18n } = useTranslation();
  //
  const auth = useSelector<RootState, AuthState>((state) => state.auth);
  //
  const { appTheme, lang, searchKeyword } = useAppSelector(
    (data: { app: AppState }) => data.app
  );
  //
  const [logout] = api.useLogoutMutation();
  //
  const dispatch = useDispatch();
  //
  const [sideBarIsActive, setSideBarIsActive] = useState<boolean>(false);
  //
  const [openDrawer, setOpenDrawer] = useState(false);
  //
  const [searchIsOpened, setSearchIsOpened] = useState(false);
  //
  const [searchKeywordDebounce] = useDebounce(searchKeyword, 100);
  //
  const _drawerToggle = () => {
    setOpenDrawer(!openDrawer);
  };
  // logout
  const AdminLogout = async () => {
    try {
      await logout({}).unwrap();
      navigate(`/`);
    } catch (error) {
      toast("logout error | " + error);
    }
  };
  //
  const changeLanguage = (lng: EnabledLanguage) => {
    // show loader
    dispatch(setLoading(true));
    // change language
    i18n.changeLanguage(lng);
    // set fields language in redux store
    dispatch(setEditedlanguage(lng));
    // set theme language in redux store
    dispatch(setLanguage(lng));
    // hide
    setTimeout(() => {
      dispatch(setLoading(false));
    }, 800);
  };
  //
  const {
    token: { borderRadiusLG },
  } = theme.useToken();
  // colorBgContainer
  //
  const Menus: MenuItem[] = MenuList.map((item, index) => {
    const mainMenuItem = getItem(
      <span key={item.url}>{item.translations?.[lang]?.title}</span>,
      item.url || index,
      item.sub_menu.length >= 1 ? (
        <FolderOpenOutlined />
      ) : item.url === "/" ? (
        <AppstoreOutlined />
      ) : (
        <FolderOutlined />
      ),
      item.sub_menu.length >= 1
        ? item.sub_menu.map((sub_menu) =>
            getItem(
              <span key={sub_menu.url}>
                {sub_menu?.translations?.[lang]?.title}
              </span>,
              sub_menu?.url,
              <FolderOutlined />
            )
          )
        : undefined
    );

    return mainMenuItem;
  });
  //
  const onSearch = () => {
    const fileterMune = MenuList?.filter((item) =>
      item?.translations?.[lang]?.title
        ?.toLowerCase()
        .includes(searchKeywordDebounce?.toLowerCase())
    );
    //
    const filterSubmenu = MenuList.flatMap((menu) =>
      menu.sub_menu
        ?.filter((item) =>
          (item?.translations?.[lang]?.title || "")
            .toLowerCase()
            .includes(searchKeywordDebounce?.toLowerCase())
        )
        .map((filteredItem) => ({
          ...filteredItem,
          parentTranslation: menu.translations,
        }))
    );
    //
    const union = [...fileterMune, ...filterSubmenu];
    //
    return union;
  };
  //
  const searchedItems = onSearch();
  //
  const showSearchResult =
    searchedItems && searchedItems.length >= 1 ? true : false;
  //
  return (
    <Wrapper $theme={appTheme}>
      <Layout
        style={{
          height: "100vh",
        }}
      >
        <Sider
          className={`custom-side-bar-class ${sideBarIsActive ? "active" : ""}`}
          theme={appTheme}
          trigger={null}
          collapsible
          width={300}
          style={{
            paddingTop: "9px",
            backgroundColor:
              appTheme === "dark" ? Colors.SecondaryBlack : Colors.lightGrey,
            overflowY: "scroll",
          }}
        >
          <Flex vertical justify="flex-end" align="flex-end">
            <Button
              size="large"
              className="collapsed-btn-class"
              type="text"
              icon={<MenuFoldOutlined />}
              onClick={() => setSideBarIsActive(!sideBarIsActive)}
            />

            <Divider
              className="collapsed-btn-class"
              style={{
                marginBlock: 0,
              }}
            />
          </Flex>

          <Menu
            onClick={({ key }) => {
              navigate(key);
              setSideBarIsActive(false); // auto close menu
            }}
            theme={appTheme}
            style={{
              background: "inherit",
            }}
            mode="inline"
            defaultSelectedKeys={["მთავარი"]}
            items={Menus}
          />
        </Sider>

        <Layout
          className="custom-layout-class-name"
          style={{
            color: "red",
          }}
        >
          <Header
            className="header-custom-class"
            style={{
              background:
                appTheme === "dark" ? Colors.SecondaryBlack : Colors.lightGrey,
            }}
          >
            <Flex
              justify="space-around"
              align="center"
              className="custom-header-flex-class-name"
            >
              <Button
                className="header-icon-btn-class"
                type="text"
                icon={
                  <RiMenuSearchLine
                    style={{
                      scale: "1.3",
                    }}
                  />
                }
                onClick={() => setSearchIsOpened(true)}
              />

              <Popover
                content={
                  <div>
                    {supportedLocales?.map((locale) => (
                      <Button
                        className="header-icon-btn-class"
                        key={locale}
                        style={{
                          width: "100%",
                          // background:
                          //   lang === locale ? Colors.secondaryBlack : "",
                        }}
                        type="text"
                        onClick={() => changeLanguage(locale)}
                      >
                        {locale?.toUpperCase()}
                      </Button>
                    ))}
                  </div>
                }
                placement="bottomRight"
              >
                <Button
                  className="header-icon-btn-class"
                  type="text"
                  name="app-language"
                  icon={<GlobalOutlined />}
                />
              </Popover>

              <Button
                className="header-icon-btn-class"
                type="text"
                name="app-theme"
                icon={appTheme === "light" ? <MoonOutlined /> : <SunOutlined />}
                onClick={() =>
                  dispatch(setAppTheme(appTheme === "dark" ? "light" : "dark"))
                }
              />

              <Button
                className="header-icon-btn-class"
                type="text"
                name="drawer-toggle"
                icon={<UserOutlined />}
                onClick={_drawerToggle}
              />

              <Tooltip placement="bottomRight" title={"გამოსვლა"}>
                <Button
                  className="header-icon-btn-class"
                  type="text"
                  name="logout"
                  icon={<LoginOutlined />}
                  onClick={AdminLogout}
                />
              </Tooltip>

              <Button
                className="collapsed-btn-class header-icon-btn-class"
                type="text"
                icon={<MenuUnfoldOutlined />}
                onClick={() => setSideBarIsActive(!sideBarIsActive)}
              />
            </Flex>
          </Header>

          <Content
            className="content-custom-class"
            style={{
              background:
                appTheme === "dark" ? Colors.SecondaryBlack : Colors.lightGrey,
              borderRadius: borderRadiusLG,
            }}
          >
            {children}
          </Content>
        </Layout>

        <Modal
          title={`${t("searchPageName")} ( ${lang?.toUpperCase()} )`}
          centered
          open={searchIsOpened}
          onCancel={() => setSearchIsOpened(false)}
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
        >
          <Input
            placeholder={t("searchPageName")}
            size="large"
            onChange={(e) => dispatch(setSearchKeyword(e.target.value))}
          />

          <Divider orientation="center" orientationMargin={0} plain>
            {t("searched")}: {searchedItems?.length}
          </Divider>

          <Card
            style={{
              height: 300,
              overflowY: "scroll",
            }}
            size="small"
          >
            {showSearchResult &&
              searchedItems.map((item, index) =>
                item.url ? (
                  <SearchedItem
                    key={index}
                    onClick={() => [
                      navigate(item?.url || ""),
                      setSearchIsOpened(false),
                    ]}
                  >
                    {item && item.parentTranslation ? (
                      <Text style={{ color: Colors.primaryRed }}>
                        {item?.parentTranslation?.[lang]?.title} /{" "}
                      </Text>
                    ) : null}
                    <Text>{item.translations?.[lang]?.title}</Text>
                  </SearchedItem>
                ) : null
              )}
          </Card>
        </Modal>

        <Drawer
          title={`${t("hi")} ${auth?.user?.firstName}`}
          onClose={_drawerToggle}
          open={openDrawer}
          style={{
            background:
              appTheme === "dark" ? Colors.SecondaryBlack : Colors.lightGrey,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              height: "100%",
              color: Colors.primaryGrey,
            }}
          >
            <div>
              <Divider orientation="left" orientationMargin={0} plain>
                {t("yourData")}
              </Divider>

              <Row>
                <Col span={24}>
                  <p className="site-description-item-profile-p">
                    {auth.user?.firstName}
                  </p>
                </Col>
                <Col span={24}>
                  <p className="site-description-item-profile-p">
                    {auth.user?.lastName}
                  </p>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <p className="site-description-item-profile-p">
                    {auth.user?.email}
                  </p>
                </Col>
              </Row>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Divider orientation="center" orientationMargin={0} plain>
                მიმდინარე გვერდის QR
              </Divider>

              <QRCode
                style={{
                  padding: "5px",
                  marginTop: "auto",
                  userSelect: "none",
                  pointerEvents: "none",
                }}
                size={200}
                value={window.location.href}
                color={Colors.primaryRed}
                bgColor={Colors.grey}
              />
            </div>
          </div>
        </Drawer>
      </Layout>
    </Wrapper>
  );
};

const SearchedItem = styled.div`
  border: 1px solid ${Colors.primaryGrey};
  border-radius: 8px;
  padding: 6px 10px;
  margin-block: 10px;
  user-select: none;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const Wrapper = styled.div<{ $theme: string }>`
  min-height: 100vh;

  .ant-layout {
    background-color: ${({ $theme }) =>
      $theme === "dark" ? Colors.Black : Colors.white};
  }
  .custom-float-button-class {
    .ant-float-btn-body {
      background-color: ${Colors.primaryRed};

      .ant-float-btn-icon {
        color: ${Colors.white};
      }
    }
  }

  .custom-header-flex-class-name {
    margin-left: auto;
  }

  .content-custom-class {
    overflow-y: scroll;
    margin: 24px 16px;
    padding: 24px;
    min-height: 280px;
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
      rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
  }

  .header-icon-btn-class {
    font-size: 16px;
    width: 64px;
    height: 64px;
  }

  .collapsed-btn-class {
    display: none;
  }

  .header-custom-class {
    display: flex;
    justify-content: space-between;
    padding: 0px;
  }

  ${Responsive.tablet} {
    .custom-layout-class-name {
      flex-direction: column-reverse;
    }

    .content-custom-class {
      margin: 0px;
      padding: 10px;
      margin-bottom: 74px;
    }

    .custom-header-flex-class-name {
      width: 100%;
    }

    .header-custom-class {
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 20;
      width: 100%;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
        rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
      padding: 0px 5px;
    }

    .custom-side-bar-class {
      background: unset !important;
      position: absolute;
      top: 0;
      left: -100%;
      opacity: 0;
      user-select: none;
      pointer-events: none;
      translate: all 200ms ease;
      z-index: 100;
      height: 100vh;
      padding: 0 !important;
      width: 100vw !important;
      max-width: unset !important;
      background-color: ${({ $theme }) =>
        $theme === "dark"
          ? Colors.SecondaryBlack
          : Colors.lightGrey} !important;

      &.active {
        translate: all 200ms ease;
        left: 0;
        opacity: unset;
        user-select: auto;
        pointer-events: auto;
      }
    }

    .collapsed-btn-class {
      display: block;
    }
  }

  ${Responsive.mobile} {
    .custom-layout-class-name {
      flex-direction: column-reverse;
    }

    .header-icon-btn-class {
      width: 40px;
      height: 40px;

      &:last-child {
        margin-right: -10px;
      }
    }

    .collapsed-btn-class {
      display: block;
    }

    .content-custom-class {
      margin: 0px;
      padding: 10px;
      margin-bottom: 74px;
    }

    .custom-header-flex-class-name {
      width: 100%;
    }

    .header-custom-class {
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 20;
      width: 100%;
      padding: 0px 5px;
    }

    .custom-side-bar-class {
      background: unset !important;
      position: absolute;
      top: 0;
      left: -100%;
      opacity: 0;
      user-select: none;
      pointer-events: none;
      translate: all 200ms ease;
      z-index: 100;
      height: 100vh;
      padding: 0 !important;
      width: 100vw !important;
      max-width: unset !important;
      background-color: ${({ $theme }) =>
        $theme === "dark"
          ? Colors.SecondaryBlack
          : Colors.lightGrey} !important;

      &.active {
        translate: all 200ms ease;
        left: 0;
        opacity: unset;
        user-select: auto;
        pointer-events: auto;
      }
    }
  }
`;
export default Admin_layout;
