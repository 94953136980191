import {
  LoadingOutlined,
  PlusOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Badge,
  Button,
  Card,
  Divider,
  Flex,
  Spin,
  Tag,
  Tooltip,
} from "antd";
import Meta from "antd/es/card/Meta";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../../api";
import { RootState } from "../../../store";
import { AuthState } from "../../../store/authSlice";
import styled from "styled-components";

const Admin_frani_users = () => {
  const auth = useSelector<RootState, AuthState>((state) => state.auth);
  const { t } = useTranslation();
  const navigate = useNavigate();

  // frani users list
  const {
    data: franiUsersList,
    isLoading: isFraniUsersListLoading,
    refetch: isFraniUsersListRefetch,
  } = api.useGetFraniUsersListQuery(undefined);

  // frani user deactivation
  const [franiuserDeactivation, { isLoading: isFraniUserDeactivationLoading }] =
    api.useFraniUserDeactivationMutation();

  // frani user activation
  const [franiuserActivation, { isLoading: isFraniUserActivationLoading }] =
    api.useFraniUserActivationMutation();

  // frani user deactivation function
  const FraniUserDeactivationFunction = async (userID: number) => {
    try {
      await franiuserDeactivation(userID).unwrap();
      toast.success(`${t("user")} ${t("deactivated")}`);
      isFraniUsersListRefetch();
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t("errorOccurred")}`);
      }
    }
  };

  // frani user activation function
  const FraniUserActivationFunction = async (userID: number) => {
    try {
      await franiuserActivation(userID).unwrap();
      toast.success(`${t("user")} ${t("activated")}`);
      isFraniUsersListRefetch();
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t("errorOccurred")}`);
      }
    }
  };

  const toggleActivation = async (item: any) => {
    if (item.activated) {
      await FraniUserDeactivationFunction(item.id);
    } else {
      await FraniUserActivationFunction(item.id);
    }
  };

  const mainLoading = isFraniUsersListLoading;

  return (
    <>
      {mainLoading ? (
        <Flex justify="center" align="center" style={{ height: "100%" }}>
          <Spin indicator={<LoadingOutlined spin />} size="large" />
        </Flex>
      ) : (
        <>
          {auth && auth?.user?.userType === "Frani-Product-Owner" ? null : (
            <Button
              size="large"
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => navigate("/frani-user-edit/0")}
            >
              {t("createUser")}
            </Button>
          )}

          <Divider />

          <GridMinmax>
            {franiUsersList &&
              franiUsersList.length >= 1 &&
              franiUsersList.map((item, index) => (
                <Card
                  loading={
                    isFraniUserDeactivationLoading ||
                    isFraniUserActivationLoading
                  }
                  key={index}
                  style={{ maxWidth: 320, width: "100%" }}
                  actions={[
                    <SettingOutlined
                      key="setting"
                      onClick={() => navigate(`/frani-user-edit/${item.id}`)}
                    />,
                    <Tag color={"blue"} onClick={() => toggleActivation(item)}>
                      {item.activated === true
                        ? t("deactivate")
                        : t("activate")}
                    </Tag>,
                  ]}
                >
                  <Flex justify="space-between">
                    <Meta
                      avatar={
                        <Avatar src="https://api.dicebear.com/7.x/miniavs/svg?seed=5" />
                      }
                      title={`${item?.firstName} ${item.lastName}`}
                    />

                    <Tooltip
                      title={
                        item?.activated === true ? t("active") : t("inActive")
                      }
                    >
                      <Badge
                        style={{ marginLeft: "20px", marginTop: "-3px" }}
                        color={item?.activated === true ? "lime" : "red"}
                      />
                    </Tooltip>
                  </Flex>

                  <Divider variant="dashed" />

                  <Flex gap={10} wrap="wrap">
                    {item?.roles?.map((r) => (
                      <Tag key={r?.id} color="purple">
                        {r?.role}
                      </Tag>
                    ))}
                  </Flex>
                </Card>
              ))}
          </GridMinmax>
        </>
      )}
    </>
  );
};

const GridMinmax = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 20px;
`;

export default Admin_frani_users;
