import { LoadingOutlined } from "@ant-design/icons";
import { Divider, Flex, Space, Spin } from "antd";
import dayjs from "dayjs";
import React from "react";
import { useTranslation } from "react-i18next";
import { MdDone } from "react-icons/md";
import { RxCross1 } from "react-icons/rx";
import styled from "styled-components";
import api from "../../../api";
import Responsive from "../../../assets/config/Responsive";
import Colors from "../../../assets/styles/Colors";
import { useAppSelector } from "../../../store";
import { AppState } from "../../../store/appSlice";

const AdminWebUserDetails: React.FC<{
  userId: number | null;
}> = ({ userId }) => {
  const { lang } = useAppSelector((root) => root.app);
  const { t } = useTranslation();

  const { appTheme } = useAppSelector((data: { app: AppState }) => data.app);

  // get existing user
  const { data: userDetailsArr, isLoading: isUserDetailsLoading } =
    api.useGetWebUserByIdQuery(userId, {
      skip: userId === null ? true : false,
      refetchOnMountOrArgChange: true,
    });

  const userDetails = userDetailsArr?.[0];

  const { data: countries = [], isLoading: isCountriesLoading } =
    api.useGetCountriesQuery(undefined);

  const MainLoading = isCountriesLoading || isUserDetailsLoading;

  return (
    <>
      {MainLoading ? (
        <Flex justify="center" align="center" style={{ height: "100%" }}>
          <Spin indicator={<LoadingOutlined spin />} size="large" />
        </Flex>
      ) : (
        <Space
          direction="vertical"
          size={"large"}
          style={{
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "14px",
            }}
          >
            <TextBox>
              <TextLeftSide $theme={appTheme}>{`${t(
                "firstName"
              )}`}</TextLeftSide>
              <TextRightSide $theme={appTheme}>
                {userDetails?.firstName}
              </TextRightSide>
            </TextBox>
            <TextBox>
              <TextLeftSide $theme={appTheme}>{`${t(
                "lastName"
              )}`}</TextLeftSide>
              <TextRightSide $theme={appTheme}>
                {userDetails?.lastName}
              </TextRightSide>
            </TextBox>
            <TextBox>
              <TextLeftSide $theme={appTheme}>{`${t("email")}`}</TextLeftSide>
              <TextRightSide $theme={appTheme}>
                {userDetails?.email}
              </TextRightSide>
            </TextBox>
            <TextBox>
              <TextLeftSide $theme={appTheme}>{`${t("phone")}`}</TextLeftSide>
              <TextRightSide $theme={appTheme}>
                {userDetails?.phone}
              </TextRightSide>
            </TextBox>
            <TextBox>
              <TextLeftSide $theme={appTheme}>{`${t(
                "personalNumber"
              )}`}</TextLeftSide>
              <TextRightSide $theme={appTheme}>
                {userDetails?.personalNumber}
              </TextRightSide>
            </TextBox>
            <TextBox>
              <TextLeftSide $theme={appTheme}>{`${t(
                "passportNumber"
              )}`}</TextLeftSide>
              <TextRightSide $theme={appTheme}>
                {userDetails?.passportNumber}
              </TextRightSide>
            </TextBox>
            <TextBox>
              <TextLeftSide $theme={appTheme}>{`${t(
                "cityzenship"
              )}`}</TextLeftSide>
              <TextRightSide $theme={appTheme}>
                {
                  countries?.find((x) => x.isoCode === userDetails?.citizenship)
                    ?.translations?.[lang]?.name
                }
              </TextRightSide>
            </TextBox>
            <TextBox>
              <TextLeftSide $theme={appTheme}>{`${t(
                "firstNameGe"
              )}`}</TextLeftSide>
              <TextRightSide $theme={appTheme}>
                {userDetails?.firstNameGe}
              </TextRightSide>
            </TextBox>
            <TextBox>
              <TextLeftSide $theme={appTheme}>{`${t(
                "lastNameGe"
              )}`}</TextLeftSide>
              <TextRightSide $theme={appTheme}>
                {userDetails?.lastNameGe}
              </TextRightSide>
            </TextBox>
            <TextBox>
              <TextLeftSide $theme={appTheme}>{`${t(
                "dateOfBirth"
              )}`}</TextLeftSide>
              <TextRightSide $theme={appTheme}>
                {userDetails?.birthday
                  ? dayjs(userDetails?.birthday).format("D MMM YYYY")
                  : ""}
              </TextRightSide>
            </TextBox>
            <TextBox>
              <TextLeftSide $theme={appTheme}>{`${t("age")}`}</TextLeftSide>
              <TextRightSide $theme={appTheme}>
                {userDetails?.age}
              </TextRightSide>
            </TextBox>
            <TextBox>
              <TextLeftSide $theme={appTheme}>{`${t("gender")}`}</TextLeftSide>
              <TextRightSide $theme={appTheme}>
                {userDetails?.gender === "male"
                  ? `${t("male")}`
                  : userDetails?.gender === "female"
                  ? `${t("female")}`
                  : ""}
              </TextRightSide>
            </TextBox>

            <Divider />

            <TextBox>
              <TextLeftSide $theme={appTheme}>{`${t(
                "sgsVerification"
              )}`}</TextLeftSide>
              <TextRightSide $theme={appTheme}>
                {userDetails?.sgsVerification ? (
                  <MdDone color={Colors.primaryGreen} />
                ) : userDetails?.sgsVerification === false ? (
                  <RxCross1 color={Colors.primaryRed} />
                ) : (
                  ""
                )}
              </TextRightSide>
            </TextBox>
            <TextBox>
              <TextLeftSide $theme={appTheme}>{`${t(
                "emailVerification"
              )}`}</TextLeftSide>
              <TextRightSide $theme={appTheme}>
                {userDetails?.emailVerification ? (
                  <MdDone color={Colors.primaryGreen} />
                ) : userDetails?.emailVerification === false ? (
                  <RxCross1 color={Colors.primaryRed} />
                ) : (
                  ""
                )}
              </TextRightSide>
            </TextBox>
            <TextBox>
              <TextLeftSide $theme={appTheme}>{`${t(
                "phoneVerification"
              )}`}</TextLeftSide>
              <TextRightSide $theme={appTheme}>
                {userDetails?.phoneVerification ? (
                  <MdDone color={Colors.primaryGreen} />
                ) : userDetails?.phoneVerification === false ? (
                  <RxCross1 color={Colors.primaryRed} />
                ) : (
                  ""
                )}
              </TextRightSide>
            </TextBox>
            <TextBox>
              <TextLeftSide $theme={appTheme}>{`${t(
                "identificated"
              )}`}</TextLeftSide>
              <TextRightSide $theme={appTheme}>
                {userDetails?.identificated ? (
                  <MdDone color={Colors.primaryGreen} />
                ) : userDetails?.identificated === false ? (
                  <RxCross1 color={Colors.primaryRed} />
                ) : (
                  ""
                )}
              </TextRightSide>
            </TextBox>

            <Divider />

            <TextBox>
              <TextLeftSide $theme={appTheme}>{`${t(
                "agreeToTerms"
              )}`}</TextLeftSide>
              <TextRightSide $theme={appTheme}>
                {userDetails?.agreeToTerms ? (
                  <MdDone color={Colors.primaryGreen} />
                ) : userDetails?.agreeToTerms === false ? (
                  <RxCross1 color={Colors.primaryRed} />
                ) : (
                  ""
                )}
              </TextRightSide>
            </TextBox>
            <TextBox>
              <TextLeftSide $theme={appTheme}>{`${t(
                "mobileNotifications"
              )}`}</TextLeftSide>
              <TextRightSide $theme={appTheme}>
                {userDetails?.enableMobileNotifications ? (
                  <MdDone color={Colors.primaryGreen} />
                ) : userDetails?.enableMobileNotifications === false ? (
                  <RxCross1 color={Colors.primaryRed} />
                ) : (
                  ""
                )}
              </TextRightSide>
            </TextBox>
            <TextBox>
              <TextLeftSide $theme={appTheme}>{`${t(
                "webNotifications"
              )}`}</TextLeftSide>
              <TextRightSide $theme={appTheme}>
                {userDetails?.enableWebNotifications ? (
                  <MdDone color={Colors.primaryGreen} />
                ) : userDetails?.enableWebNotifications === false ? (
                  <RxCross1 color={Colors.primaryRed} />
                ) : (
                  ""
                )}
              </TextRightSide>
            </TextBox>
            <TextBox>
              <TextLeftSide $theme={appTheme}>{`${t(
                "emailNotifications"
              )}`}</TextLeftSide>
              <TextRightSide $theme={appTheme}>
                {userDetails?.enableEmailNotifications ? (
                  <MdDone color={Colors.primaryGreen} />
                ) : userDetails?.enableEmailNotifications === false ? (
                  <RxCross1 color={Colors.primaryRed} />
                ) : (
                  ""
                )}
              </TextRightSide>
            </TextBox>

            <Divider />

            <TextBox>
              <TextLeftSide $theme={appTheme}>{`${t("active")}`}</TextLeftSide>
              <TextRightSide $theme={appTheme}>
                {userDetails?.activated ? `${t("active")}` : `${t("inActive")}`}
              </TextRightSide>
            </TextBox>
            <TextBox>
              <TextLeftSide $theme={appTheme}>{`${t("lang")}`}</TextLeftSide>
              <TextRightSide $theme={appTheme}>
                {userDetails?.lang}
              </TextRightSide>
            </TextBox>
            <TextBox>
              <TextLeftSide $theme={appTheme}>{`${t("avatar")}`}</TextLeftSide>
              <TextRightSide $theme={appTheme}>
                {userDetails?.avatar}
              </TextRightSide>
            </TextBox>

            <Divider />

            <TextBox>
              <TextLeftSide $theme={appTheme}>{`${t(
                "createdDate"
              )}`}</TextLeftSide>
              <TextRightSide $theme={appTheme}>
                {dayjs(userDetails?.createdAt).format("DD MMMM YYYY HH:mm:ss")}
              </TextRightSide>
            </TextBox>
            <TextBox>
              <TextLeftSide $theme={appTheme}>{`${t(
                "lastUpdatedAt"
              )}`}</TextLeftSide>
              <TextRightSide $theme={appTheme}>
                {dayjs(userDetails?.updatedAt).format("DD MMMM YYYY HH:mm:ss")}
              </TextRightSide>
            </TextBox>
          </div>
        </Space>
      )}
    </>
  );
};

const TextBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  ${Responsive.mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
`;
const TextLeftSide = styled.span<{ $theme: string }>`
  color: ${({ $theme }) =>
    $theme === "dark" ? Colors.nevadaGrey : Colors.primaryGrey};
  font-size: 14px;
`;
const TextRightSide = styled.span<{ $theme: string }>`
  color: ${({ $theme }) =>
    $theme === "dark" ? Colors.lightGrey : Colors.primaryBlack};
  font-size: 14px;
`;

export default AdminWebUserDetails;
