import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Badge, Button, Card, Divider, Flex, Spin } from "antd";
import Meta from "antd/es/card/Meta";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import api from "../../../api";
import { useAppSelector } from "../../../store";

const Admin_travel_multi_day = () => {
  //
  const { t } = useTranslation();
  //
  const navigate = useNavigate();
  //
  const { lang } = useAppSelector((root) => root.app);
  //
  const {
    data: multiDays,
    isLoading: isMultiDaysLoading,
    refetch,
  } = api.useGetTravelMultiDaysQuery(undefined);
  //
  useEffect(() => {
    refetch();
  }, [refetch]);
  //
  return (
    <>
      {isMultiDaysLoading ? (
        <Flex justify="center" align="center" style={{ height: "100%" }}>
          <Spin indicator={<LoadingOutlined spin />} size="large" />
        </Flex>
      ) : (
        <>
          <Button
            size="large"
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => navigate("/travel-multi-day-edit/0")}
          >
            {t("create")}
          </Button>

          <Divider />

          <Flex gap={20} wrap="wrap" justify="space-evenly">
            {multiDays?.map((item, index) => (
              <Card
                style={{
                  maxWidth: "350px",
                }}
                loading={isMultiDaysLoading}
                key={index}
                hoverable={true}
                onClick={() => navigate(`/travel-multi-day-edit/${item.id}`)}
              >
                <Meta
                  title={item?.translations[lang]?.title || ""}
                  description={item?.translations[lang]?.body || ""}
                />

                {item?.translations[lang]?.excerpt || item.days ? (
                  <Divider />
                ) : null}

                <Flex vertical gap={20}>
                  {item?.translations[lang]?.excerpt && (
                    <Badge.Ribbon
                      text={t("additionalInformation")}
                      color="blue"
                      style={{
                        marginTop: "-20px",
                      }}
                    >
                      <Card size="small">
                        {item?.translations[lang]?.excerpt}
                      </Card>
                    </Badge.Ribbon>
                  )}

                  {item.days && (
                    <Badge.Ribbon
                      text={t("days")}
                      color="blue"
                      style={{
                        marginTop: "-20px",
                      }}
                    >
                      <Card size="small">{item.days}</Card>
                    </Badge.Ribbon>
                  )}
                </Flex>
              </Card>
            ))}
          </Flex>
        </>
      )}
    </>
  );
};

export default Admin_travel_multi_day;
