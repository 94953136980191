import { LoadingOutlined } from "@ant-design/icons";
import { Badge, Card, Divider, Flex, Select, Spin } from "antd";
import Meta from "antd/es/card/Meta";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import api from "../../../../api";
import { TranslationsField } from "../../../../domain/types";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { AppState, setEditedlanguage } from "../../../../store/appSlice";
import AdminLangSwicher from "../../../admin_components/AdminLangSwicher";

const Admin_manage_travel_pages = () => {
  //
  const dispatch = useAppDispatch();
  //
  const navigate = useNavigate();
  //
  const { t } = useTranslation();
  //
  const { lang } = useAppSelector((root) => root.app);
  //
  const { field_Language } = useAppSelector(
    (data: { app: AppState }) => data.app
  );
  // page slugs
  const pageSlugs: {
    id: number;
    slug: string;
    text: string;
    translations: TranslationsField<{ text: string }>;
  }[] = [
    {
      id: 1,
      slug: "homepage-product-category",
      text: "პროდუქტების კატეგორიები",
      translations: {
        en: {
          text: "Product categories",
        },
        ge: {
          text: "პროდუქტების კატეგორიები",
        },
      },
    },
    {
      id: 2,
      slug: "incoming-or-outgoing",
      text: "შემომავალი თუ გამავალი",
      translations: {
        en: {
          text: "To Georgia / Abroad",
        },
        ge: {
          text: "შემომავალი თუ გამავალი",
        },
      },
    },
    {
      id: 3,
      slug: "company-choose",
      text: "ფრანის & მომხმარებლის არჩევანი",
      translations: {
        en: {
          text: "Frani choice & user choice",
        },
        ge: {
          text: "ფრანის & მომხმარებლის არჩევანი",
        },
      },
    },
    {
      id: 4,
      slug: "insurance-kind",
      text: "ერთჯერადი & მრავალჯერადი მოგზაურობა",
      translations: {
        en: {
          text: "Single & multi travel",
        },
        ge: {
          text: "ერთჯერადი & მრავალჯერადი მოგზაურობა",
        },
      },
    },
    {
      id: 5,
      slug: "travel-abroad-single-and-multi-date-text",
      text: "ერთჯერადი & მრავალჯერადი თარიღები",
      translations: {
        en: {
          text: "Single & multi dates",
        },
        ge: {
          text: "ერთჯერადი & მრავალჯერადი თარიღები",
        },
      },
    },
    {
      id: 6,
      slug: "area",
      text: "არეალი",
      translations: {
        en: {
          text: "Area",
        },
        ge: {
          text: "არეალი",
        },
      },
    },
    {
      id: 7,
      slug: "currency",
      text: "ვალუტა & ლიმიტი & ფრანშიზა",
      translations: {
        en: {
          text: "Currency / Limit / Deductible",
        },
        ge: {
          text: "ვალუტა & ლიმიტი & ფრანშიზა",
        },
      },
    },
    {
      id: 8,
      slug: "travel-policy-registration-first-step",
      text: "ჩემთვის & სხვისთვის & ჩემთვის და სხვისთვის & ქარდები",
      translations: {
        en: {
          text: "For me /  For other /  For me & other",
        },
        ge: {
          text: "ჩემთვის & სხვისთვის & ჩემთვის და სხვისთვის & ქარდები",
        },
      },
    },
  ];
  // selected slug
  const [selectedSlug, setSelectedSlug] = useState<string | null>(null);

  // get travel flow
  const { data: pageTextArry, isLoading: isPageTextArrayLoading } =
    api.useGetTravelFlowPageTextsBySlugQuery(selectedSlug as string, {
      skip: !selectedSlug && true,
    });
  //
  return (
    <>
      <Flex justify="space-between" align="center" gap={20}>
        <Select
          size="large"
          style={{
            width: "100%",
          }}
          defaultValue={selectedSlug}
          onChange={(value) => setSelectedSlug(value)}
          options={pageSlugs.map((item) => ({
            value: item.slug,
            label: item.translations?.[lang].text,
          }))}
          placeholder={t("choosePage")}
        />

        <AdminLangSwicher
          onChange={(value) => dispatch(setEditedlanguage(value))}
        />
      </Flex>

      {isPageTextArrayLoading ? (
        <Flex justify="center" align="center" style={{ height: "100%" }}>
          <Spin indicator={<LoadingOutlined spin />} size="large" />
        </Flex>
      ) : (
        <>
          <Divider />

          <Flex gap={20} wrap="wrap" justify="space-evenly">
            {pageTextArry?.map((item, index) => (
              <>
                <Card
                  style={{
                    width: "250px",
                  }}
                  loading={isPageTextArrayLoading}
                  key={index}
                  hoverable={true}
                  onClick={() =>
                    navigate(`/manage-travel-pages-edit/${item?.id}`)
                  }
                  cover={
                    <img
                      alt={`${item?.image}---${index}`}
                      src={item?.image}
                      style={{
                        paddingTop: "20px",
                        height: "100px",
                        objectFit: "contain",
                      }}
                    />
                  }
                >
                  <Meta
                    title={item?.translations?.[field_Language]?.title || ""}
                    description={
                      item?.translations?.[field_Language]?.body || ""
                    }
                  />

                  {item?.translations?.[field_Language]?.excerpt ||
                  item?.translations?.[field_Language]?.secondTitle ||
                  item?.translations?.[field_Language]?.secondExcerpt ||
                  item?.translations?.[field_Language]?.thirdTitle ||
                  item?.translations?.[field_Language]?.thirdExcerpt ? (
                    <Divider />
                  ) : null}

                  <Flex vertical gap={20}>
                    {item?.translations?.[field_Language]?.excerpt && (
                      <Badge.Ribbon
                        text={t("additionalInformation")}
                        color="blue"
                        style={{
                          marginTop: "-20px",
                        }}
                      >
                        <Card size="small">
                          {item?.translations?.[field_Language]?.excerpt}
                        </Card>
                      </Badge.Ribbon>
                    )}

                    {item?.translations?.[field_Language]?.secondTitle && (
                      <Badge.Ribbon
                        text={t("secondTitle")}
                        color="blue"
                        style={{
                          marginTop: "-20px",
                        }}
                      >
                        <Card size="small">
                          {item?.translations?.[field_Language]?.secondTitle}
                        </Card>
                      </Badge.Ribbon>
                    )}

                    {item?.translations?.[field_Language]?.secondExcerpt && (
                      <Badge.Ribbon
                        text={t("secondAdditionalInformation")}
                        color="blue"
                        style={{
                          marginTop: "-20px",
                        }}
                      >
                        <Card size="small">
                          {item?.translations?.[field_Language]?.secondExcerpt}
                        </Card>
                      </Badge.Ribbon>
                    )}

                    {item?.translations?.[field_Language]?.thirdTitle && (
                      <Badge.Ribbon
                        text={t("thirdTitle")}
                        color="blue"
                        style={{
                          marginTop: "-20px",
                        }}
                      >
                        <Card size="small">
                          {item?.translations?.[field_Language]?.thirdTitle}
                        </Card>
                      </Badge.Ribbon>
                    )}

                    {item?.translations?.[field_Language]?.thirdExcerpt && (
                      <Badge.Ribbon
                        text={t("thirdAdditionalInformation")}
                        color="blue"
                        style={{
                          marginTop: "-20px",
                        }}
                      >
                        <Card size="small">
                          {item?.translations?.[field_Language]?.thirdExcerpt}
                        </Card>
                      </Badge.Ribbon>
                    )}
                  </Flex>
                </Card>
              </>
            ))}
          </Flex>
        </>
      )}
    </>
  );
};
export default Admin_manage_travel_pages;
