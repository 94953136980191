import {
  CaretRightOutlined,
  LoadingOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Badge,
  Button,
  Card,
  Collapse,
  Divider,
  Flex,
  Spin,
  Tooltip,
} from "antd";
import Text from "antd/es/typography/Text";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import api from "../../../api";
import { numberWithSpaces } from "../../../domain/types";
import { useAppSelector } from "../../../store";
//
const AdminProducts = () => {
  //
  const { t } = useTranslation();
  //
  const navigate = useNavigate();
  //
  const { lang } = useAppSelector((root) => root.app);
  //
  const [selectedCompanyID, setSelectedCompanyID] = useState<
    number | undefined
  >();
  // get product list by id
  const { data: ProductListById, isLoading: isProductListByIdLoading } =
    api.useGetProductListByCategoryIdQuery(selectedCompanyID as number, {
      skip: !selectedCompanyID,
    });
  // company list
  const { data: companyList, isLoading: isCompanyListLoading } =
    api.useCompanyListQuery(undefined);
  //
  return (
    <>
      {isCompanyListLoading ? (
        <Flex justify="center" align="center" style={{ height: "100%" }}>
          <Spin indicator={<LoadingOutlined spin />} size="large" />
        </Flex>
      ) : (
        <>
          <Divider dashed style={{ marginBottom: "25px" }}>
            {t("companies")}
          </Divider>

          <Flex gap={30} wrap="wrap" justify="space-evenly">
            {companyList?.map((item, index) => (
              <Badge.Ribbon
                key={index}
                text={
                  (item.brandName?.[lang]?.title || "no name")?.substring(
                    0,
                    10
                  ) + (item.brandName?.[lang]?.title?.length > 10 ? "..." : "")
                }
                style={{
                  userSelect: "none",
                  marginTop: "-17px",
                }}
              >
                <Card
                  hoverable
                  onClick={() => setSelectedCompanyID(item.id)}
                  style={{
                    width: "90px",
                    height: "90px",
                    outline:
                      item.id === selectedCompanyID
                        ? "2px solid #1677ff"
                        : "none",
                  }}
                >
                  {isProductListByIdLoading && selectedCompanyID === item.id ? (
                    <Spin indicator={<LoadingOutlined spin />} size="large" />
                  ) : (
                    <Avatar
                      shape="square"
                      size="large"
                      src={
                        <img
                          src={item?.logo}
                          alt={`${item?.logo}-alt`}
                          style={{ objectFit: "contain" }}
                        />
                      }
                    />
                  )}
                </Card>
              </Badge.Ribbon>
            ))}
          </Flex>
        </>
      )}

      {ProductListById && (
        <>
          <Divider dashed>{t("products")}</Divider>
          <Flex vertical gap={20} wrap="wrap" justify="space-evenly">
            {ProductListById.map((item, index) => (
              <Collapse
                key={index}
                items={[
                  {
                    key: index,
                    label: (
                      <Flex justify="space-between">
                        <span>{item?.productCategory?.title}</span>

                        <Tooltip
                          title={
                            item?.integrated
                              ? t("integrated")
                              : t("nonIntegrated")
                          }
                        >
                          <Badge
                            style={{
                              marginTop: "-3px",
                            }}
                            color={item?.integrated ? "limo" : "red"}
                          />
                        </Tooltip>
                      </Flex>
                    ),
                    extra: (
                      <Tooltip title={t("editProduct")}>
                        <SettingOutlined
                          onClick={() => {
                            let path = "/";
                            if (item.targetType === "TravelProduct") {
                              path = `/travel-product-edit/${item.targetId}`;
                            } else if (item.targetType === "TplProduct") {
                              path = `/tpl-product-edit/${item.targetId}`;
                            } else if (item.targetType === "WelcomerProduct") {
                              path = `/welcomer-product-edit/${item.targetId}`;
                            } else if (item.targetType === "PetProduct") {
                              path = `/pet-product-edit/${item.targetId}`;
                            }

                            navigate(path);
                          }}
                          style={{ marginLeft: "20px" }}
                        />
                      </Tooltip>
                    ),
                    children: (
                      <Flex vertical gap={15}>
                        {item?.packages?.map((pkg, pkgIndex) => (
                          <Collapse
                            expandIcon={({ isActive }) => (
                              <CaretRightOutlined rotate={isActive ? 90 : 0} />
                            )}
                            key={pkgIndex}
                            items={[
                              {
                                key: pkgIndex,
                                label: (
                                  <Flex justify="space-between">
                                    {pkg?.title}

                                    <Tooltip
                                      title={
                                        pkg?.status?.charAt(0)?.toUpperCase() +
                                        pkg?.status?.slice(1)
                                      }
                                    >
                                      <Badge
                                        style={{
                                          whiteSpace: "nowrap",
                                          marginTop: "-3px",
                                        }}
                                        color={
                                          pkg?.status === "draft"
                                            ? "volcano"
                                            : "green"
                                        }
                                      />
                                    </Tooltip>
                                  </Flex>
                                ),
                                children: (
                                  <Flex vertical gap={10}>
                                    {item?.productCategoryId === 1 && (
                                      <Flex gap={20}>
                                        <Text style={{ minWidth: "150px" }}>
                                          {t("multiPack")}:
                                        </Text>
                                        <Text>
                                          {/* @ts-ignore */}
                                          {pkg.target?.enableMulti
                                            ? `${t("yes")}`
                                            : `${t("no")}`}
                                        </Text>
                                      </Flex>
                                    )}

                                    {item?.productCategoryId === 1 && (
                                      <Flex gap={20}>
                                        <Text style={{ minWidth: "150px" }}>
                                          {t("area")}:
                                        </Text>
                                        <Text>
                                          {/* @ts-ignore */}
                                          {pkg.target?.areas
                                            ?.map((a) => a.area)
                                            .join(", ")}
                                        </Text>
                                      </Flex>
                                    )}

                                    <Flex gap={20}>
                                      <Text style={{ minWidth: "150px" }}>
                                        {t("limit")}:
                                      </Text>
                                      <Text>
                                        {numberWithSpaces(
                                          pkg.compensationLimit
                                        )}{" "}
                                      </Text>
                                    </Flex>

                                    <Flex gap={20}>
                                      <Text style={{ minWidth: "150px" }}>
                                        {t("currency")}:
                                      </Text>
                                      <Text>
                                        {pkg?.compensationCurrencies
                                          .map((c) => c.currency)
                                          .join(", ")}
                                      </Text>
                                    </Flex>

                                    <Flex gap={20}>
                                      <Text style={{ minWidth: "150px" }}>
                                        {t("activationDate")}:
                                      </Text>
                                      <Text>
                                        {dayjs(pkg.activatedDate).format(
                                          "DD.MM.YYYY | HH:mm"
                                        )}
                                      </Text>
                                    </Flex>
                                  </Flex>
                                ),
                              },
                            ]}
                          />
                        ))}
                        <Button
                          size="large"
                          type="primary"
                          onClick={() => {
                            let path = "/";
                            if (item.targetType === "TravelProduct") {
                              path = `/travel-product-edit/${item.targetId}`;
                            } else if (item.targetType === "TplProduct") {
                              path = `/tpl-product-edit/${item.targetId}`;
                            } else if (item.targetType === "WelcomerProduct") {
                              path = `/welcomer-product-edit/${item.targetId}`;
                            } else if (item.targetType === "PetProduct") {
                              path = `/pet-product-edit/${item.targetId}`;
                            }

                            navigate(path);
                          }}
                        >
                          {t("editProduct")}
                        </Button>
                      </Flex>
                    ),
                  },
                ]}
              />
            ))}
          </Flex>
        </>
      )}
    </>
  );
};

export default AdminProducts;
