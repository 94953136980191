import { LoadingOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Divider,
  Empty,
  Flex,
  Input,
  Select,
  Space,
  Spin,
  Tag,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import api from "../../api";
import Loader from "../../components/Loader";
const { Text } = Typography;

const Admin_manage_emails = () => {
  const { t } = useTranslation();

  // selected product id
  const [selectedProductId, setSelectedProductId] = useState<
    number | undefined
  >(undefined);

  // existing product emails
  const [existingEmailsArray, setExistingEmailsArray] = useState<string[] | []>(
    []
  );

  console.log("existingEmailsArray", existingEmailsArray);

  // entered email
  const [enteredEmail, setEnteredEmail] = useState<string | undefined>("");
  const [isDelete, setIsDelete] = useState<boolean>(false);

  // product categories list
  const {
    data: AdminProductCategoriesList,
    isLoading: isAdminProductCategoriesListLoading,
    refetch: isAdminProductCategoriesListRefetch,
  } = api.useGetAdminProductCategoriesListQuery(undefined);

  //  get frani product current emails
  const {
    data: getFraniProductCurrentEmail,
    isLoading: isGetFraniProductCurrentEmailLoading,
    refetch: isGetFraniProductCurrentEmailRefetch,
    isError: isGetFraniProductCurrentEmailError,
  } = api.useGetFraniProductCurrentEmailQuery(selectedProductId as number, {
    skip: !selectedProductId && true,
  });

  // remvoe frani product current emails
  const [
    removeFraniProductCurrentEmail,
    {
      isLoading: isRemoveFraniProductCurrentEmailsLoading,
      isSuccess: removeRemoveFraniProductCurrentEmailsSucces,
    },
  ] = api.useRemoveFraniProductMailsMutation();

  // store frani product emails mutation
  const [
    storeFraniProductEmails,
    { isLoading: isStoreFraniProductEmailsLoading },
  ] = api.useStoreFraniProductEmailsMutation();

  // store frani product emails mutation
  const [
    updateFraniProductEmails,
    { isLoading: isUpdateFraniProductEmailsLoading },
  ] = api.useUpdateFraniProductEmailsMutation();

  // store frani product emails function
  const StoreFraniProductEmailsFun = async () => {
    if (AdminProductCategoriesList) {
      try {
        await storeFraniProductEmails({
          productCategoryId: selectedProductId,
          emails: existingEmailsArray,
        }).unwrap();
        // refetch product emails
        isAdminProductCategoriesListRefetch();
        isGetFraniProductCurrentEmailRefetch();
        toast.success(`${t("createdSuccessfully")}`);
      } catch (error) {
        if (error?.data?.errors?.constructor === Array) {
          const firstError = error.data.errors[0];
          const errorMessage = firstError.message;
          toast.error(errorMessage);
        } else {
          toast.error(`${t("errorOccurred")}`);
        }
      }
    }
  };

  // store frani product emails function
  const updateFraniProductEmailsFun = async () => {
    if (AdminProductCategoriesList) {
      try {
        await updateFraniProductEmails({
          productCategoryId: selectedProductId,
          emails: existingEmailsArray,
        }).unwrap();
        // refetch product emails
        isAdminProductCategoriesListRefetch();
        isGetFraniProductCurrentEmailRefetch();
        setIsDelete(false);
        toast.success(`${t("updatedSuccessfully")}`);
      } catch (error) {
        if (error?.data?.errors?.constructor === Array) {
          const firstError = error.data.errors[0];
          const errorMessage = firstError.message;
          toast.error(errorMessage);
        } else {
          toast.error(`${t("errorOccurred")}`);
        }
      }
    }
  };

  // remove frani product emails function
  const removeFraniProductEmailsFun = async () => {
    if (window.confirm(`${t("certainToDeleteIt")}`) && selectedProductId) {
      try {
        await removeFraniProductCurrentEmail(selectedProductId).unwrap();
        // refetch product emails
        isAdminProductCategoriesListRefetch();
        isGetFraniProductCurrentEmailRefetch();
        setExistingEmailsArray([]);
        toast.success(`${t("deletedSuccessfully")}`);
      } catch (error) {
        if (error?.data?.errors?.constructor === Array) {
          const firstError = error.data.errors[0];
          const errorMessage = firstError.message;
          toast.error(errorMessage);
        } else {
          toast.error(`${t("errorOccurred")}`);
        }
      }
    }
  };

  // set existing emails
  useEffect(() => {
    if (AdminProductCategoriesList && getFraniProductCurrentEmail) {
      setExistingEmailsArray(getFraniProductCurrentEmail.emails);
    }

    if (!getFraniProductCurrentEmail || isGetFraniProductCurrentEmailError) {
      setExistingEmailsArray([]);
    }
    return () => {};
  }, [
    getFraniProductCurrentEmail,
    AdminProductCategoriesList,
    isGetFraniProductCurrentEmailError,
  ]);

  useEffect(() => {
    if (removeRemoveFraniProductCurrentEmailsSucces) {
      // refetch product emails
      isAdminProductCategoriesListRefetch();
      isGetFraniProductCurrentEmailRefetch();
      setExistingEmailsArray([]);
    }
  }, [
    removeRemoveFraniProductCurrentEmailsSucces,
    isAdminProductCategoriesListRefetch,
    isGetFraniProductCurrentEmailRefetch,
  ]);

  // main loader
  const MainLoader =
    isAdminProductCategoriesListLoading ||
    isGetFraniProductCurrentEmailLoading ||
    isStoreFraniProductEmailsLoading ||
    isRemoveFraniProductCurrentEmailsLoading ||
    isUpdateFraniProductEmailsLoading;

  return MainLoader ? (
    <Loader />
  ) : (
    <>
      {MainLoader ? (
        <Flex justify="center" align="center" style={{ height: "100%" }}>
          <Spin indicator={<LoadingOutlined spin />} size="large" />
        </Flex>
      ) : (
        <>
          {AdminProductCategoriesList && (
            <Space direction="vertical" style={{ width: "100%" }}>
              <Text>{t("selectProduct")}</Text>
              <Select
                style={{
                  width: "100%",
                }}
                placeholder={t("selectProduct")}
                size="large"
                value={selectedProductId || undefined}
                onChange={(value: number) => setSelectedProductId(value)}
                options={[
                  ...(AdminProductCategoriesList?.map((item) => ({
                    value: item.id,
                    label: item.title,
                  })) || []),
                ]}
              />
            </Space>
          )}

          {existingEmailsArray && selectedProductId && (
            <>
              <Divider variant="dashed" />

              <Space direction="vertical" style={{ width: "100%" }}>
                <Text>{t("selectProduct")}</Text>

                <Space.Compact style={{ width: "100%" }}>
                  <Input
                    style={{ width: "100%" }}
                    size="large"
                    type="email"
                    placeholder={t("addNewEmail")}
                    value={enteredEmail || ""}
                    onChange={(e) => setEnteredEmail(e.target.value)}
                  />
                  <Button
                    size="large"
                    type="primary"
                    onClick={() => {
                      if (
                        enteredEmail &&
                        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                          enteredEmail
                        )
                      ) {
                        setExistingEmailsArray((prevNames) => [
                          ...prevNames,
                          enteredEmail,
                        ]);
                        setEnteredEmail("");
                      } else {
                        toast.error(`${t("validEmail")}`);
                      }
                    }}
                  >
                    {t("add")}
                  </Button>
                </Space.Compact>
              </Space>

              <Flex gap={20}>
                {(existingEmailsArray &&
                  existingEmailsArray.length === 0 &&
                  isGetFraniProductCurrentEmailError) ||
                (getFraniProductCurrentEmail?.emails.length === 0 &&
                  existingEmailsArray.length === 0) ? (
                  <Card
                    size="small"
                    style={{
                      width: "100%",
                      minHeight: 216,
                      marginTop: 20,
                    }}
                  >
                    <Flex
                      justify="center"
                      style={{ width: "100%", marginBlock: 30 }}
                    >
                      <Empty description={t("emailNotFound")} />
                    </Flex>
                  </Card>
                ) : (
                  <Card
                    size="small"
                    style={{
                      width: "100%",
                      minHeight: 216,
                      marginTop: 20,
                    }}
                  >
                    {existingEmailsArray?.map(
                      (email_item: string, email_index: number) => {
                        return (
                          <Tag
                            style={{
                              marginBlock: 4,
                            }}
                            key={email_index}
                            closable
                            bordered
                            color={"purple"}
                            onClose={(e) =>
                              existingEmailsArray && [
                                setExistingEmailsArray(
                                  existingEmailsArray.filter(
                                    (x) => x !== email_item
                                  )
                                ),
                              ]
                            }
                          >
                            {email_item}
                          </Tag>
                        );
                      }
                    )}
                  </Card>
                )}
              </Flex>
            </>
          )}
        </>
      )}

      <Flex gap={20} style={{ marginTop: 20 }} wrap>
        {AdminProductCategoriesList && selectedProductId && (
          <Button
            style={{ width: "100%" }}
            size="large"
            danger
            type="default"
            onClick={removeFraniProductEmailsFun}
          >
            {t("deleteProductEmails")}
          </Button>
        )}

        {AdminProductCategoriesList && selectedProductId && (
          <Button
            style={{ width: "100%" }}
            size="large"
            type="primary"
            onClick={
              (!getFraniProductCurrentEmail &&
                isGetFraniProductCurrentEmailError) ||
              isGetFraniProductCurrentEmailError ||
              isDelete === true
                ? StoreFraniProductEmailsFun
                : updateFraniProductEmailsFun
            }
          >
            {(!getFraniProductCurrentEmail &&
              isGetFraniProductCurrentEmailError) ||
            isGetFraniProductCurrentEmailError ||
            isDelete === true
              ? `${t("create")}`
              : `${t("update")}`}
          </Button>
        )}
      </Flex>
    </>
  );
};

export default Admin_manage_emails;
