import {
  CloudUploadOutlined,
  LinkOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { Badge, Flex, Spin } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import styled from "styled-components";
import api from "../../api";

const DocumentFieldUploader = ({
  url,
  onChange,
  width = 300,
  language,
}: {
  url: string;
  onChange: (newUrl: string) => void;
  width?: number;
  language?: string;
}) => {
  //
  const [uploadFileMutation, { isLoading: isUploadFileLoading }] =
    api.useUploadFileMutation();
  //
  const { t } = useTranslation();
  //
  const uniqueId = `file-${Math.random().toString(36).substring(2, 9)}`;
  //
  return (
    <Wrapper>
      {isUploadFileLoading ? (
        <Flex justify="center" align="center" style={{ height: "100%" }}>
          <Spin indicator={<LoadingOutlined spin />} size="large" />
        </Flex>
      ) : (
        <Badge.Ribbon
          text={language || t("lang")}
          style={{
            marginTop: "-20px",
            width: "fit-content",
          }}
        >
          <Block>
            {url && url !== "file-goes-here.pdf" ? (
              <Iframe loading="lazy" src={url} title={url} />
            ) : (
              <b>{t("noFileChosen")}</b>
            )}

            <BottomSheet>
              <IconSpan onClick={() => window.open(url, "_blank")}>
                <LinkOutlined />
              </IconSpan>

              <div>
                <InputFileLabel htmlFor={uniqueId}>
                  <IconSpan>
                    <CloudUploadOutlined />
                  </IconSpan>
                </InputFileLabel>
                <InputFile
                  id={uniqueId}
                  type="file"
                  onChange={async (event) => {
                    const file = event?.target?.files?.[0];
                    if (!file) {
                      toast.error(`${t("pleaseChooseFile")}`);
                      return;
                    }
                    const result = await uploadFileMutation({ file }).unwrap();
                    onChange(result.url);
                  }}
                />
              </div>
            </BottomSheet>
          </Block>
        </Badge.Ribbon>
      )}
    </Wrapper>
  );
};
//
const IconSpan = styled.span`
  padding: 10px;
  display: grid;
  place-items: center;
  cursor: pointer;

  svg {
    height: 25px;
    width: 50px;
    transition: 100ms ease;
    color: white;
  }

  &:hover {
    transition: 100ms ease;
    transform: scale(1.08);
  }
`;
const InputFileLabel = styled.label``;
const InputFile = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;
const BottomSheet = styled.div`
  background-color: #323639;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 55px;
  transition: bottom 0.3s ease-in-out;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 20px;
`;

const Iframe = styled.iframe`
  width: inherit;
  height: inherit;
`;

const Block = styled.div`
  max-width: 350px;
  width: 100%;
  height: 400px;
  border-radius: 4px;
  position: relative;
  overflow: hidden;

  &:hover {
    ${BottomSheet} {
      bottom: 0;
    }
  }

  &:not(:hover) {
    ${BottomSheet} {
      bottom: -100%;
    }
  }
`;

const Wrapper = styled.div`
  height: 400px;
  width: fit-content;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
`;

export default DocumentFieldUploader;
