import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Badge, Button, Card, Divider, Flex, Spin } from "antd";
import Title from "antd/es/typography/Title";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../api";
import { useAppSelector } from "../../../store";
import { AppState } from "../../../store/appSlice";
import AdminLangSwicher from "../../admin_components/AdminLangSwicher";
//
const AdminPolicyDeactivationReasons = () => {
  //
  const { slug } = useParams();
  //
  const { t } = useTranslation();
  //
  const navigate = useNavigate();
  //
  const { field_Language } = useAppSelector(
    (data: { app: AppState }) => data.app
  );
  //
  const { data: productCategories, isLoading: isProductCategoryLoading } =
    api.useGetAdminProductCategoriesListQuery(undefined);
  //
  const categoryId = productCategories?.find((x) => x.slug === slug)?.id || 0;
  // get policy deactive reason
  const {
    data: deactiveReasonArr,
    isLoading: isDeaactiveReasonArrLoading,
    refetch,
  } = api.useGetAdminPolicyDeactiveReasonQuery({
    categoryId,
  });
  //
  useEffect(() => {
    refetch();
    return () => {};
  }, [refetch]);
  // main loader
  const MainLoader = isDeaactiveReasonArrLoading || isProductCategoryLoading;
  //
  return (
    <>
      {MainLoader ? (
        <Flex justify="center" align="center" style={{ height: "100%" }}>
          <Spin indicator={<LoadingOutlined spin />} size="large" />
        </Flex>
      ) : (
        <>
          <Flex justify="space-between">
            <Button
              size="large"
              type="primary"
              icon={<PlusOutlined />}
              onClick={() =>
                navigate(`/${slug}/policy-deactivation-reason-detail/0`)
              }
            >
              {t("create")}
            </Button>

            <AdminLangSwicher />
          </Flex>

          <Divider />

          <Flex gap={20} wrap="wrap" justify="space-evenly">
            {deactiveReasonArr?.map((item, index) => (
              <Badge.Ribbon
                style={{
                  marginTop: "-20px",
                }}
                text={item.isActive ? t("active") : t("inActive")}
                color={item.isActive ? "green" : "red"}
              >
                <Card
                  key={index}
                  hoverable
                  style={{
                    maxWidth: "350px",
                    width: "100vw",
                    display: "grid",
                    placeItems: "center",
                    height: "100%",
                  }}
                  onClick={() =>
                    navigate(
                      `/${slug}/policy-deactivation-reason-detail/${item.id}`
                    )
                  }
                >
                  <Title level={5}>
                    {item.translations?.[field_Language]?.title ||
                      t("textNotFound")}
                  </Title>
                </Card>
              </Badge.Ribbon>
            ))}
          </Flex>
        </>
      )}
    </>
  );
};

export default AdminPolicyDeactivationReasons;
