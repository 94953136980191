import {
  CloseCircleOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Divider,
  Drawer,
  Flex,
  Input,
  Popconfirm,
  Spin,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import api from "../../../api";
//
const AdminTraveFranchise = () => {
  //
  const { t } = useTranslation();
  //
  const [drawerStatus, setDrawerStatus] = useState<boolean>(false);
  //
  const [editedFranchise, setEditedFranchise] = useState<number | null>(null);
  // franchaise array
  const {
    data: franchiseList,
    isLoading: isFranchiseLoading,
    refetch: refetchFranchiseList,
  } = api.useGetAdminTravelFranchiseListQuery("travel-abroad-franchise");
  //
  const [saveTravelFranchise, { isLoading: isSaveFranchiseLoading }] =
    api.useAddAdminTravelFranchiseMutation();
  //
  const [
    removeFranchise,
    { isLoading: isRemoveFranchiseLoading, isSuccess: removeFranchiseSucces },
  ] = api.useRemoveFranchiseMutation();
  //
  //close add trave franchise drawer
  const onCloseDrawer = () => {
    setDrawerStatus(false);
    setEditedFranchise(null);
  };
  //
  const saveFranchiseFunction = async () => {
    if (editedFranchise) {
      try {
        await saveTravelFranchise({ franchise: editedFranchise }).unwrap();
        // refetch franchise list
        refetchFranchiseList();
        // message for user
        toast.success(`${t("deductible")} ${t("addedSuccessfully")}`);
        // close modal
        onCloseDrawer();
        //
      } catch (error) {
        if (error?.data?.errors?.constructor === Array) {
          const firstError = error.data.errors[0];
          const errorMessage = firstError.message;
          toast.error(errorMessage);
        } else {
          toast.error(`${t("errorOccurred")}`);
        }
      }
    } else {
      toast.error(`${t("enterDeductible")}`);
    }
  };
  //
  useEffect(() => {
    if (removeFranchiseSucces) {
      refetchFranchiseList();
    }
  }, [removeFranchiseSucces, refetchFranchiseList]);
  //
  const drawerLoading = isSaveFranchiseLoading;
  //
  return (
    <>
      {isFranchiseLoading || isRemoveFranchiseLoading ? (
        <Flex justify="center" align="center" style={{ height: "100%" }}>
          <Spin indicator={<LoadingOutlined spin />} size="large" />
        </Flex>
      ) : (
        <>
          <Button
            size="large"
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setDrawerStatus(!drawerStatus)}
          >
            {t("create")}
          </Button>

          <Divider />

          <Flex vertical={false} wrap="wrap" gap={20}>
            {franchiseList &&
              franchiseList.map((item, index) => (
                <Card
                  key={index}
                  hoverable
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                    width: "100px",
                  }}
                >
                  {item.value}

                  <Popconfirm
                    title={t("certainToDeleteIt")}
                    onConfirm={() => removeFranchise(item.id)}
                    onCancel={() => {}}
                    okText="Yes"
                    cancelText="No"
                    placement="bottom"
                  >
                    <Button
                      size="small"
                      type="text"
                      style={{
                        display: "grid",
                        placeItems: "center center",
                        position: "absolute",
                        top: "5px",
                        right: "5px",
                      }}
                    >
                      <CloseCircleOutlined />
                    </Button>
                  </Popconfirm>
                </Card>
              ))}
          </Flex>
        </>
      )}

      <Drawer
        title={t("createDeductible")}
        onClose={onCloseDrawer}
        open={drawerStatus}
      >
        {drawerLoading ? (
          <Flex justify="center" align="center">
            <Spin indicator={<LoadingOutlined spin />} size="large" />
          </Flex>
        ) : (
          <Flex vertical>
            <Input
              size="large"
              placeholder={t("deductible")}
              value={editedFranchise || ""}
              type="number"
              onChange={(event) =>
                setEditedFranchise(event?.target?.value as unknown as number)
              }
              suffix={
                <Tooltip title={t("deductible")}>
                  <InfoCircleOutlined />
                </Tooltip>
              }
            />

            <Divider />

            <Button size="large" type="primary" onClick={saveFranchiseFunction}>
              {t("create")}
            </Button>
          </Flex>
        )}
      </Drawer>
    </>
  );
};
//
export default AdminTraveFranchise;
